<template>
  <div class="wishList">
    <div class="tabs">
      <div
        class="tab"
        v-for="(item, index) in tabs"
        :class="{
          isSelect: indexTab === index,
        }"
        :key="item.key"
        @click="onRefresh(item, index)"
      >
        <div class="tab_text">{{ item.label }}</div>
        <div class="tab_line"></div>
      </div>
    </div>
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
      :commonOperaParams="commonOperaParams"
    >
      <div
        v-for="(item, index) in listData"
        :key="index"
        class="oItem"
        @click="toDetail(item)"
      >
        <div class="oItem-avatar">
          <img
            :src="
              item.avatar
                ? item.avatar
                : require('@/assets/img/default_avatar.png')
            "
            alt=""
          />
        </div>
        <div class="oItem-wrap">
          <div class="oItem-head">
            <div class="oItem-headName">
              <div class="oItem-tl oItem-name">
                <span>{{
                  item.userName ? formatStrFunc(item.userName) : ""
                }}</span>
              </div>
            </div>
            <div class="oItem-t">
              <div class="oItem-tr oItem-time">
                <span>{{ item.insertTime }}</span>
              </div>
            </div>
          </div>
          <div class="oItem-b">
            <div class="oitem-b_tag">
              <span>#{{ getStatus(item) }}#</span>
            </div>
            <div class="oItem-b_text">
              <span>{{ item.wishContent }}</span>
            </div>
          </div>
          <div class="oItem-c" v-if="item.wishPic">
            <img :src="item.wishPic" alt="" />
          </div>
        </div>
      </div>
    </v-list>
  </div>
</template>

<script>
import { wishWallUrl } from "./api.js";
import { getOption, formatStr } from "@/utils/utils.js";
import { wishType } from "./map.js";
import { gloabalCount } from "@/utils/common.js";
var moment = require("moment");
export default {
  name: "myWishes",
  data() {
    return {
      tabs: [
        {
          key: "",
          label: "全部",
        },
        {
          key: 1,
          label: "愿望",
        },
        {
          key: 2,
          label: "祝福",
        },
        {
          key: 3,
          label: "其他",
        },
      ],
      indexTab: 0,
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 20,
      },
      commonOperaParams: {
        createText: "发布心愿",
        listText: "我的心愿",
        imgUrl: "listWillBtn",
        isCommonOpera: true,
        name: "未来心愿发布",
        operaUrl: "wishes",
        myUrl: "myWishes",
      },
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    // this.getActivityList();
    gloabalCount("", 9, 1);
  },
  methods: {
    formatStrFunc(str) {
      // console.log(formatStr(str));
      if (str) {
        return formatStr(str);
      }
    },
    getStatus(item) {
      if (item.wishType) {
        return getOption(item.wishType, wishType, "key").value;
      } else {
        return "";
      }
    },
    onRefresh(item, i) {
      if (this.indexTab === i) {
        return;
      }
      this.listData = [];
      this.indexTab = i;
      this.requestData = {
        curPage: 1,
        pageSize: 20,
      };
      this.getWishList(item.key);
    },
    onLoad() {
      this.getWishList();
    },
    getWishList(type) {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        houseId: this.houseId || this.communityId || undefined,
        tenantId: this.tenantId,
        wishType: type || undefined,
      };
      this.$axios.get(`${wishWallUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          console.log("数据更新");
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.listData = [];
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (item.avatar) {
              item.avatar = this.$handleImg(80, 80, item.avatar);
            }
            if (item.wishPic && item.wishPic.length > 0) {
              item.wishPic = this.$handleImg(
                (document.body.clientWidth - 80) * 2,
                Math.ceil((((document.body.clientWidth - 80) * 2) / 16) * 9),
                item.wishPic
              );
            }

            this.listData.push(item);
          });
        } else {
        }
      });
    },
    toDetail(item) {
      this.$router.push({
        name: "wishDetail",
        query: { id: item.id },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.wishList {
  margin-top: 96px;
  min-height: 100vh;
  // background: #f7f7f7;
  // padding-bottom: 410px;

  padding-bottom: 96px;
  box-sizing: border-box;
  ::v-deep .van-list {
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  }
  .tabs {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 96px;
    box-shadow: 0px 4px 0px 0px #f0f0f0;
    display: flex;
    background: #fff;
    .tab {
      flex: 1;
      text-align: center;
      line-height: 96px;
      position: relative;
      &.isSelect {
        font-weight: bold;
        .tab_line {
          position: absolute;
          bottom: -4px;
          // transform: translateY(-50%);
          width: 30%;
          height: 8px;
          margin: 0 35%;
          background: #007eff;
          border-radius: 4px;
        }
      }
      .tab_text {
        font-size: 28px;
        font-weight: 400;
        color: #323334;
      }
      // .tab_line {
      // }
    }
  }
  .oItem {
    background: #fff;
    padding: 48px 32px 46px;
    border-bottom: 2px solid #ebebeb;
    // margin-bottom: 30px;
    display: flex;

    .oItem-avatar {
      width: 80px;
      height: 80px;
      min-width: 80px;
      /*border-radius:30px;*/
      /*border: 0.5px solid #999;*/
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .oItem-wrap {
      flex: 1;
      max-width: calc(100% - 80px);
      box-sizing: border-box;
      padding-left: 16px;
      .oItem-head {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        // margin-bottom: 10px;
        align-items: center;
        .oItem-headName {
          display: flex;
          align-items: center;
          font-size: 28px;
        }
        .oItem-t {
          display: flex;
          justify-content: space-between;
          font-weight: bold;
          font-size: 20px;
        }
        .oItem-name {
          margin-left: 15px;
          font-size: 32px !important;
          font-weight: bold;
        }
        .oItem-time {
          font-size: 28px !important;
          font-weight: normal !important;
          color: #999999;
        }
      }
      .oItem-b {
        font-size: 32px;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // margin-left: 30px;
        line-height: 44px;
        .oitem-b_tag {
          font-size: 32px;
          font-weight: 400;
          color: #fe6f16;
          margin-top: 16px;
        }
        .oItem-b_text {
          font-size: 32px;
          font-weight: 400;
          color: #333333;
          margin-top: 8px;
        }
      }
      .oItem-c {
        height: calc(100vw / 16 * 9);
        margin: 8px 0 0;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
    }
  }
}
</style>
